<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <loader v-if="loading" />
          <v-row v-else>
            <create-team @created="getTeams" />
            <update-team
              :id="selectedTeam"
              @updated="getTeams"
            />

            <delete-team
              :id="selectedTeam"
              @deleted="getTeams"
            />

            <v-col
              lg="8"
              md="8"
              sm="12"
              cols="12"
            >
              <v-text-field
                v-model="search"
                filled
                append-icon="mdi-magnify"
                background-color="white"
                label="Pesquisar"
                hint="Digite o nome do time e aperte enter"
                persistent-hint
                @click:append="getTeams"
                v-on:keyup.enter="getTeams"
              />
            </v-col>

            <v-col
              lg="4"
              md="4"
              sm="12"
              cols="12"
              class="d-flex flex-row"
            >
              <v-select
                v-model="teams.pageSize"
                :items="pageSizeItens"
                filled
                background-color="white"
                label="Itens a serem carregados"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createTeam()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar time</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="team in teams.data"
              :key="team._id"
            >
              <team-card
                :data="team"
                @updateSelected="updateTeam"
                @deleteSelected="deleteTeam"
              />
            </v-col>

            <v-col
              v-if="teams.data.length > 0"
              cols="12"
            >
              <div class="text-center">
                <v-pagination
                  v-model="teams.currentPage"
                  :length="teams.totalPages"
                ></v-pagination>
              </div>
            </v-col>

            <v-col
              v-else
              cols="12"
            >
              <empty-data />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    TeamCard: () => import('./components/TeamCard.vue'),
    CreateTeam: () => import('./components/CreateTeam.vue'),
    UpdateTeam: () => import('./components/UpdateTeam.vue'),
    DeleteTeam: () => import('./components/DeleteTeam.vue')
  },
  data () {
    return {
      loading: false,
      search: '',
      selectedTeam: '',
      pageSizeItens: [
        5,
        10,
        25
      ]
    }
  },
  watch: {
    'teams.currentPage' (val) {
      this.getTeams()
    },
    'teams.pageSize' (val) {
      this.getTeams()
    }
  },
  computed: {
    teams: {
      get () {
        return this.$store.state.teams
      },
      set (val) {
        this.$store.commit('SET_TEAMS', val)
      }
    },
    createTeamModal: {
      get () {
        return this.$store.state.createTeamModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createTeamModal',
          value: val
        })
      }
    },
    updateTeamModal: {
      get () {
        return this.$store.state.updateTeamModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateTeamModal',
          value: val
        })
      }
    },
    deleteTeamModal: {
      get () {
        return this.$store.state.deleteTeamModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteTeamModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getTeams()
  },
  methods: {
    getTeams () {
      const { currentPage: page, pageSize } = this.teams
      this.loading = true

      var url = `/teams?page=${page}&pageSize=${pageSize}`
      if (this.search) {
        url = `${url}&search=${this.search}`
      }

      this.$http.get(url)
        .then((res) => {
          this.loading = false
          this.teams = res.data
          if (page > res.data.totalPages) {
            this.teams = {
              ...this.teams,
              currentPage: 1
            }
          }
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
    },
    createTeam () {
      this.createTeamModal = true
    },
    updateTeam (id) {
      this.selectedTeam = id
      this.updateTeamModal = true
    },
    deleteTeam (id) {
      this.selectedTeam = id
      this.deleteTeamModal = true
    }
  }
}
</script>
